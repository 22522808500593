<!--
* @description:
* @fileName tagEdit.vue
* @author hvv
* @date 2022/01/18 09:36:48
!-->
<template>
  <el-dialog
    v-model="dialogFormVisible"
    :title="title"
    width="500px"
    @close="close"
  >
    <el-form ref="formRef" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="标签名称" prop="name">
        <el-input v-model="form.name" />
      </el-form-item>

      <el-form-item label="标签类型" prop="type">
        <el-select v-model="form.type" placeholder="请选择标签类型">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import {
    defineComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'
  import { addTag, editTag } from '@/api/active'

  export default defineComponent({
    name: 'tagEdit',
    emits: ['fetch-data'],
    props: {
      enterpriseCode: {
        default: '',
        type: String,
      },
    },
    setup(props, { emit }) {
      const { proxy } = getCurrentInstance()

      const state = reactive({
        formRef: null,
        treeRef: null,
        checkMenu: [],
        form: {},
        rules: {
          name: [
            { required: true, trigger: 'blur', message: '请输入标签名称' },
          ],
          type: [
            { required: true, trigger: 'change', message: '请选择标签类型' },
          ],
        },
        title: '',
        dialogFormVisible: false,
        list: [],
        options: [
          {
            label: '活动',
            value: 'ACTIVITY',
          },
          {
            label: '文章',
            value: 'ARTICLE',
          },
          {
            label: '店铺',
            value: 'SHOP',
          },
        ],
      })
      // 新增type 字段 shop 只新增店铺标签
      const showEdit = async (row, type) => {
        if (type == 'shop') {
          state.options = [
            {
              label: '店铺',
              value: 'SHOP',
            },
          ]
        }
        if (!row) {
          state.title = '添加'
        } else {
          state.title = '编辑'
          state.form = Object.assign({}, row)
        }

        if (!!props.enterpriseCode) {
          state.options = [
            {
              label: '文章',
              value: 'ARTICLE',
            },
          ]
        }
        state.dialogFormVisible = true
      }
      const close = () => {
        state['formRef'].resetFields()
        state.form = {
          btnRolesCheckedList: [],
        }
        state.dialogFormVisible = false
      }

      const save = () => {
        state['formRef'].validate(async (valid) => {
          if (valid) {
            let msg = ''
            if (!!props.enterpriseCode) {
              state.form.enterpriseCode = props.enterpriseCode
            }
            if (state.title == '编辑') {
              let data = await editTag(state.form)
              msg = data.msg
            } else {
              let data = await addTag(state.form)
              msg = data.msg
            }
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
            emit('fetch-data')
            close()
          }
        })
      }
      return {
        ...toRefs(state),
        showEdit,
        close,
        save,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .vab-tree-border {
    height: 200px;
    padding: $base-padding;
    overflow-y: auto;
    border: 1px solid #dcdfe6;
    border-radius: $base-border-radius;
  }
</style>
